// Each export is an array of extensions
// This allows collaborators not to know about what specific dependencies
// each extension have (ex: this allows not exposing that Tables depend on
// TableCell, TableRow, TableHeader, BubbleMenu)

// This group includes extensions that have been extended
export { default as TiptapBase } from './base';
export { default as Heading } from './heading';
export { default as Blockquote } from './blockquote';
export { default as BulletList } from './bullet-list';
export { default as OrderedList } from './ordered-list';
export { default as Bold } from './bold';
export { default as Color } from './color';
export { default as Italic } from './italic';
export { default as Strike } from './strike';
export { default as History } from './history';
export { default as FontFamily } from './font-family';
export { default as Link } from './link';
export { default as Underline } from './underline';
export { default as TextAlign } from './text-align';
export { default as Tables } from './tables';
export { default as TaskLists } from './task-lists';
export { default as Images } from './images';
export { default as AutoSaveNotes } from './auto_save_notes';

// This group includes pure tiptap extensions (that have not been extendend)
import HardBreakExtension from '@tiptap/extension-hard-break';
import HorizontalRuleExtension from '@tiptap/extension-horizontal-rule';
import ListItemExtension from '@tiptap/extension-list-item';
import DropcursorExtension from '@tiptap/extension-dropcursor';
import GapcursorExtension from '@tiptap/extension-gapcursor';
import TextStyleExtension from '@tiptap/extension-text-style';

export const HardBreak = [HardBreakExtension];
export const HorizontalRule = [HorizontalRuleExtension];
export const ListItem = [ListItemExtension];
export const Dropcursor = [DropcursorExtension];
export const Gapcursor = [GapcursorExtension];
export const TextStyle = [TextStyleExtension];
