/* global DfApi */

import { Extension } from '@tiptap/core';

const api = () => new DfApi();
const savingMessage = '<span class="spinner"></span> Saving...';
const saveErrorMessage = `<div title="If the error persists please contact customer service">
  <i class="fa-regular fa-circle-exclamation"></i> Autosave error! Retrying...
</div>`;

const AutoSaveNotes = Extension.create({
  name: 'autoSaveNotes',
  addOptions() {
    const matches = /\/projects\/(\d+)/.exec(window.location.href);

    return {
      projectId: matches ? matches[1] : null,
    };
  },
  onBeforeCreate({ editor }) {
    scheduleUpdateSavedAt(editor, this);
  },
  onUpdate({ editor }) {
    scheduleSaveNotes(editor, this);
  },
});

const scheduleUpdateSavedAt = (editor, extension) => {
  let updateInfoTimerId = extension.options['updateInfoTimerId'];

  if (updateInfoTimerId) {
    window.clearTimeout(updateInfoTimerId);
  }
  updateInfoTimerId = window.setInterval(updateSavedAt, 60000, editor, extension);
  extension.options['updateInfoTimerId'] = updateInfoTimerId;
};

const updateSavedAt = (editor, extension, withIcon = false) => {
  const savedAt = extension.options['lastSavedAt'];

  if (savedAt) {
    const icon = withIcon ? '<i class="fa-regular fa-circle-check"></i> ' : '';
    const date = savedAt.toLocaleDateString('en-US');
    const time = savedAt.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    const title = `${date} at ${time}`;
    setSaveInfo(
      editor,
      `<div title="${title}">${icon}Last saved ${timeElapsed(savedAt)} ago</div>`
    );
  }
};

const scheduleSaveNotes = (editor, extension, timeout = 2000) => {
  const timerId = extension.options['autoSaveTimerId'];
  if (timerId) {
    window.clearTimeout(timerId);
  }
  extension.options['autoSaveTimerId'] = window.setTimeout(saveNotes, timeout, editor, extension);
};

const saveNotes = async (editor, extension) => {
  scheduleUpdateSavedAt(editor, extension); // reset the last saved message timer
  setSaveInfo(editor, savingMessage);
  const content = editor.getHTML();
  const response = await api().saveDesignerNotes(extension.options['projectId'], content);
  response.ok ? saveNotesSuccess(editor, extension, response) : saveNotesError(editor);
};

const setSaveInfo = (editor, message) => {
  const editorContainer = editor.view.dom.closest('[data-controller="tiptap-editor"]');
  const autoSaveElement = editorContainer.querySelector('[data-js-auto-save]');
  autoSaveElement.innerHTML = message;
};

const saveNotesSuccess = async (editor, extension, response) => {
  const date = await response.json();
  extension.options['lastSavedAt'] = date ? new Date(date) : new Date();
  updateSavedAt(editor, extension, true);
};

const saveNotesError = (editor) => {
  setSaveInfo(editor, saveErrorMessage);
  scheduleSaveNotes(editor, 10000);
};

// TODO: move this method in a shared helper module
const timeElapsed = (lastSavedAt) => {
  const msPerMinute = 1000 * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msElapsed = Date.now() - lastSavedAt;

  if (msElapsed < msPerMinute) {
    return 'seconds';
  } else if (msElapsed < msPerHour) {
    return pluralize(Math.round(msElapsed / msPerMinute), 'minute');
  } else if (msElapsed < msPerDay) {
    return pluralize(Math.round(msElapsed / msPerHour), 'hour');
  } else if (msElapsed < msPerDay * 31) {
    return pluralize(Math.round(msElapsed / msPerDay), 'day');
  } else {
    return 'more than a month';
  }
};

// TODO: move this method in a shared helper module
const pluralize = (count, noun) => {
  return `${count} ${noun}${count === 1 ? '' : 's'}`;
};

export default [AutoSaveNotes];
